<script>
  export let prices,
    variant,
    isHearth = false;
  import Slider from "./Slider.svelte";
  import { _ } from "svelte-i18n";

  let value;
  if (!isHearth) {
    value = variant && (variant.code === "monthly" ? "monthly" : "full");
  } else {
    value = "full";
  }

  let variants;
  $: {
    if (prices) {
      variants = Object.values(
        prices.reduce((variants, { variant }) => {
          if (!(variant.id in variants)) variants[variant.id] = variant;
          return variants;
        }, {})
      );
    } else {
      variants = [];
    }
  }

  let monthly, full, monthlyNotAvailable;
  $: monthly = variants.find(({ code }) => code === "monthly");
  $: annual = variants.find(({ code }) => code === "annually");
  $: monthlyNotAvailable = !monthly;
  $: full = variants.find(({ code }) => code !== "monthly");
  $: if (monthlyNotAvailable || !value)
    if (annual) {
      value = "annually";
    } else {
      value = "full";
    }
  $: {
    switch (value) {
      case "monthly":
        variant = monthly;
        break;
      case "annually":
        variant = annual;
        break;
      default:
        variant = full;
        break;
    }
  }
</script>

<section>
  <h2>{@html $_("choice.Which payment type is best for you ?")}</h2>

  <Slider
    bind:value
    leftValue="monthly"
    rightValue="full"
    disabled={monthlyNotAvailable}
  >
    <span slot="left">{@html $_("choice.Monthly Payment")}</span>

    <span slot="right">
      {#if annual} Annual Payment {:else} {@html $_("choice.Full Payment")}{/if}
    </span>
  </Slider>

  {#if variant && monthlyNotAvailable}
    <slot name="monthly-not-available" />
  {/if}
</section>
